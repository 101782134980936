import React from 'react';
import MySwaggerPage from '../components/MySwaggerPage';

const IndexPage = () => {
  return(
    <>
      <MySwaggerPage />
    </>
  );
};

export default IndexPage;
